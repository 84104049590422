import React from "react"
import Footer from "../../components/Footer/Footer";

const styles = require("../../styles/pages/General.module.scss");

export default function Terms() {
    return (
        <div>
            <section>
                <h1>Terms of Service</h1>
                <h2>1. Introduction</h2>
                <span>Last updated June 5th, 2021</span>

                <p>These terms of service (the "terms", or "terms of service") create a legal agreement between you (a
                    "user") and Prestavi, Inc. ("we", "our", "us"). These terms govern your use of the services, software,
                    and websites (together, the "services") provided by us, superseding any prior agreements between you and
                    us. By accessing or using our services, you acknowledge and agree that you have read, understand, and
                    agree to be bound by these terms. If you do not agree to these terms, then you have no right to access
                    or use the services.</p>

                <p>Violation of any of the terms below may result in the immediate termination of your account. Your actions
                    may also subject you to legal consequences.</p>
            </section>

            <section>
                <h2>2. General Terms</h2>

                <p>To use our services, you must meet the following criteria:</p>

                <ol>
                    <li>You must be at least 16 years of age.</li>
                    <li>Provide your full legal name, a valid email address and any other information requested to complete
                        the signup process.
                    </li>
                    <li>Provide current and accurate information.</li>
                </ol>

                <p>You are responsible for all content you provide and your activities on our services. User accounts may
                    only be used by one person - a single login shared by multiple people is not permitted.</p>

                <p>You are responsible for maintaining the security of your account and password. You are responsible for
                    notifying us at support@prestavi.com if you become aware of any unauthorized use or access of your
                    account. We cannot and will not be liable for any losses, damages, liability, or attorneys’ fees from
                    your failure to comply with security obligations or the result of someone else using your password or
                    account.</p>

                <p>You may not use our services for any illegal or unauthorized purpose. You will not use our services to
                    upload, send, or otherwise distribute any objectionable content, as solely determined by us.</p>

            </section>

            <section>
                <h2>3. Billing Terms</h2>

                <p>When you sign up for our paid services, you agree to a monthly or annual subscription contract with us.
                    You agree that our PCI-compliant third-party payment processor is authorized to submit a charge to your
                    credit card or bank on a monthly or yearly recurring basis for your subscription (in addition to any
                    applicable taxes or other charges) for as long as your subscription continues. Your subscription is
                    continuous until you cancel it or we suspend or stop providing access to the services.</p>

                <p>Our services are billed in advance on a monthly or yearly basis and are non-refundable.</p>

            </section>

            <section>
                <h2>4. Free Trial</h2>
                <p>We may offer free trial periods of our services to new users. We may change the duration of free trials
                    at any time. We may close free trial accounts at our sole discretion. We may change the usage limits or
                    feature restrictions for free trial accounts at any time.</p>
            </section>


            <section>
                <h2>5. Cancellation and Termination</h2>

                <p>You may close your account and effectively cancel your subscription by sending an email to
                    support@prestavi.com. Such cancellation notice must be sent by the user who created the account, also
                    known as the account owner.</p>

                <p>Inactivity does not cause automatic cancellation and you will be continuously charged for our services
                    unless you cancel your subscription in accordance to these terms. Cancellation requests will take effect
                    at the end of the billing cycle in which the cancellation request was made.</p>

                <p>We also reserve the right, but do not have any obligation, to refuse service to anyone and close your
                    account(s) without notice for any or no reason at all.</p>
            </section>

            <section>
                <h2>6. Our Proprietary Rights</h2>

                <p>We own Prestavi and our services. You may not copy, reproduce, alter, modify, resell, mirror, or create
                    derivative works of our services or our content without our written permission.</p>

                <p>As long as you comply with our terms, we grant you a limited, non-exclusive, non-transferable,
                    non-sublicensable privilege to use our services. Use of our services is at your sole risk. The services
                    are provided on an "as is" and "as available" basis. You understand that we use third-party service
                    providers and vendors to provide the necessary hardware, software, networking, storage, and related
                    technology required to run the service.</p>

                <p>You shall grant to us a royalty-free, worldwide, transferable, sub-licensable, irrevocable and perpetual
                    license to incorporate into Prestavi or otherwise use any suggestions, enhancement requests,
                    recommendations or other feedback that we receive from you or your agents.</p>
            </section>

            <section>
                <h2>7. User Generated Content</h2>

                <p>You own your content, as defined in our Privacy Policy. We claim no intellectual property rights over the material you provide when using our services. Content uploaded or added remain yours.</p>

                <p>If you decide to stop using our services it will be up to you to extract any data you want to keep before it is destroyed. We may or may not have the ability to export portions of your data. When your account is closed, all of your data will be completely and irrevocably destroyed within 30 days from the date of the account closure. We reserve the right to close your account at any time and make no guarantees about retrieving your data.</p>

            </section>

            <section>
                <h2>8. API Terms</h2>

                <p>You may access some of your account data using the Prestavi API (Application Programming Interface). Any use of the API, including use of the API through a third-party product that accesses Harvest, is bound by these terms.</p>

                <p>You expressly understand and agree that we shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses (even if we have been advised of the possibility of such damages) resulting from your use of the API or third-party products that access your data using the API.</p>

                <p>Abuse or excessively frequent requests the API may result in the temporary or permanent suspension of your access to the API. We may, at our sole discretion, determine abuse or excessive usage of the API. We reserve the right at any time to modify or discontinue, temporarily or permanently, your access to the API (or any part thereof) with or without notice.</p>

            </section>

            <section>
                <h2>9. Privacy</h2>

                <p>For information about how we collect, process, use, and share the data we collect from and about you, please see our <a
                    href="/legal/privacy">Privacy Policy</a>.</p>

            </section>

            <section>
                <h2>10. Warranties, Disclaimers, and Limitation of Liability</h2>

                <p>Your use of our services is at your own risk. Our services are provided "as is" and "as available" without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. In addition, we do not represent or warrant that our services are accurate, complete, reliable, current, safe, uninterrupted or free of errors. While we attempt to make your access to and use of our services safe, we cannot and do not represent or warrant that our services or servers are free of viruses or other harmful components. You assume the entire risk as to the quality and performance of the services. We do not control, endorse, or take responsibility for any marketing content, third-party content, or third-party services made available or linked to by our services.</p>

                <p>In no event shall we be liable to you or any other person or entity for any indirect, special, incidental, consequential, or punitive damages arising out of or in connection with our services, any information or recommendations appearing on our services, or any link provided on our services, whether or not we have been advised of the possibility of such damages and whether based upon warrant, contract, tort, strict liability, violation of statute, or otherwise. This exclusion of liability shall apply to the fullest extent permitted by the law. In any event, our aggregate liability will not exceed the amount paid for our services.</p>

            </section>

            <section>
                <h2>11. Indemnity</h2>

                <p>You agree to indemnify, defend, and hold us harmless from and against all demands, loss, liability, claims or expenses (including attorney’s fees) made against us by any third-party due to or arising out of or in connection with your use of our services.</p>

            </section>

            <section>
                <h2>12. Governing Law</h2>

                <p>These terms shall be construed in accordance with and governed by the laws of the United States of America and by the laws of the State of Washington notwithstanding its conflicts of law provisions. You agree to the personal jurisdiction by and venue in the state and federal courts in Clark County, Washington and waive any objection to such jurisdiction or venue. Any claim under these Terms of Use must be brought within one (1) year after the cause of action arises, or such claim or cause of action is barred.</p>

            </section>

            <section>
                <h2>13. Force Majeure</h2>

                <p>Under no circumstances shall we be held liable for any delay or failure in performance resulting directly or indirectly from an event beyond our reasonable control.</p>

            </section>

            <section>
                <h2>14. No Waiver</h2>

                <p>Our failure to exercise or enforce any right or provision of these terms shall not constitute a waiver of such right or provision.</p>

            </section>

            <section>
                <h2>15. Severability</h2>

                <p>If one or more sections of the Terms are held unenforceable, then those sections will be removed or edited as little as necessary, and the rest of the Terms will still be valid and enforceable.</p>

            </section>

            <section>
                <h2>16. Assignments</h2>

                <p>You may not assign any of your rights under this agreement to anyone else. We may assign our rights to any other individual or entity at our full discretion.</p>

            </section>

            <section>
                <h2>17. Changes to the terms</h2>

                <p>We reserve the right, at our sole discretion, to change, modify, add, or remove portions of the terms, at any time without notice. check this page periodically for updates.</p>

                <p>If you do not agree to, or cannot comply with, the modified terms, you must immediately stop using the services. Updated terms will take effect upon their posting and will apply going forward.</p>

                <p>Any new features that augment or enhance the service, including the release of new tools and resources, shall be subject to the terms. Your continued use of the services after any such update constitutes your binding acceptance of any changes.</p>

            </section>

            <section>
                <h2>18. Updates to Prestavi</h2>

                <p>We reserve the right, at any time, without notice to you, to modify or change any part of Prestavi, to interrupt the operation and availability of Prestavi, or any part of Prestavi, as necessary to perform routine or non-routine maintenance, error correction, or other changes.</p>
            </section>

            <section>
                <h2>19. Questions</h2>

                <p>Please send any questions to <span className={styles.Highlight}>support@prestavi.com</span> or write to us at our mailing address listed below:</p>
                <p className={styles.Address}>
                    <span>Prestavi</span>
                    <br/>
                    <span>19215 SE 34th Street</span>
                    <br/>
                    <span>Suite 106 PMB 526</span>
                    <br/>
                    <span>Camas, WA 98607</span>
                    <br/>
                    <span>United States</span>
                </p>
            </section>

            <Footer />
        </div>
    )
}

